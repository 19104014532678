import PropTypes from 'prop-types';
import React from 'react';

const Document = ({ link, title }) => (
  <li className="flex items-center mb-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="w-6 mr-2 icon-link"
    >
      <path
        className="secondary"
        d="M19.48 13.03l-.02-.03a1 1 0 1 1 1.75-.98A6 6 0 0 1 16 21h-4a6 6 0 1 1 0-12h1a1 1 0 0 1 0 2h-1a4 4 0 1 0 0 8h4a4 4 0 0 0 3.48-5.97z"
      />
      <path
        className="primary"
        d="M4.52 10.97l.02.03a1 1 0 1 1-1.75.98A6 6 0 0 1 8 3h4a6 6 0 1 1 0 12h-1a1 1 0 0 1 0-2h1a4 4 0 1 0 0-8H8a4 4 0 0 0-3.48 5.97z"
      />
    </svg>
    <a href={link}>{title}</a>
  </li>
);

Document.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
};

export default Document;
