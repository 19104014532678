import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import Document from '../components/Document';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SidebarLogin from '../components/SidebarLogins';
import Wrapper from '../components/Wrapper';

const SmallBusinessPage = ({ data }) => (
  <Layout>
    <Wrapper>
      <SEO title="Small Business" />
      <article className="max-w-2xl mx-auto w-full">
        <div className="max-w-md pt-4 px-4">
          <Link to="/small-business/">
            <h1 className="text-5xl">Small Business</h1>
          </Link>
          <hr />
        </div>
        <div className="flex flex-wrap">
          <div className="max-w-md pb-4 px-4 w-full">
            <h2>Business Start-up</h2>
            <p>
              We can help you with everything you need to start up a new
              business, from purchasing, feasibility reports, employment, wages,
              GST, what entity to use, ABNs and everything else. We suggest you
              come in and have a review of your ideas, to work out if this is
              the right opportunity and then we have a series of small meetings
              to tackle each step of the setup process to ensure you’re not
              overwhelmed.
            </p>

            <h2>Succession Planning</h2>
            <p>
              Who is going to take over your business and how? Eventually, you
              will step away and the right plan needs to be in place to make
              sure you maximise your time in the business by stepping out of it
              the right way.
            </p>

            <h2>Company Secretary</h2>
            <p>
              All your ASIC compliance, minutes, annual returns, reporting and
              changes in company and trust structures are important to keep on
              top of, let us help to make sure you don’t miss something. This is
              a very important part of any business and one that is heavily
              regulated and fined frequently.
            </p>
          </div>
          <div className="flex-1 pb-4 px-4 w-full lg:w-1/4">
            <div className="lg:ml-24">
              <h2>Logins</h2>
              <SidebarLogin />
              <h2>Helpful Documents</h2>
              <ul className="list-reset leading-loose">
                <Document link="/small-business/abn.pdf" title="ABNs" />
                <Document
                  link="/small-business/business-names.pdf"
                  title="Business Names"
                />
              </ul>
              <Img fluid={data.imageBusiness.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </article>
    </Wrapper>
  </Layout>
);

SmallBusinessPage.propTypes = {
  data: PropTypes.object,
};

export default SmallBusinessPage;

export const pageQuery = graphql`
  query {
    imageBusiness: file(relativePath: { eq: "business.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
